import React from "react"
import styled from "styled-components"
import Search from "../components/search/search"
import Heading from "../utils/heading"

const Wrapper = styled.div`
  ${props => props.theme.wrappersContainers.primaryWrapper};
  margin: 1rem auto 0 auto;

  @media ${props => props.theme.mq.sm} {
    margin: 1.5rem auto 0 auto;
  }

  @media ${props => props.theme.mq.md} {
    margin: 0 auto;
  }

  .title{
    ${props => props.theme.fonts.h3}
  }
  
`

const LandingPage = ({ data }) => {

  function prefilter() {
    return undefined;
  }

  return (

    <>
      <Wrapper>
        <Heading type="title" as="h1" color={props => props.theme.colors.secondary}>
          Properties
        </Heading>
      </Wrapper>

      <Wrapper>
        <Search showResults={true} filters={prefilter()} />
      </Wrapper>
    </>

  )
}

export default LandingPage